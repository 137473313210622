.Header {
  padding-top: 35px;
  background-color: #FFFFFF; }
  @media (min-width: 960px) {
    .Header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      transition: padding 225ms, box-shadow 225ms, background-color 0.3s; }
      .Header.Header__scroll {
        padding: 16px 0;
        border-bottom: 1px solid #E7E8EC; }
        .Header.Header__scroll .Header__note {
          display: none; } }
  @media (max-width: 959px) {
    .Header {
      padding: 20px 0; } }
  .Header .DesktopMessenger__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between; }
  .Header__logo {
    display: inline-block;
    width: 56px;
    height: 56px;
    margin-left: -2px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='56' height='56' viewBox='0 0 56 56' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='50%25' y1='100%25' x2='50%25' y2='.008%25' id='a'%3E%3Cstop stop-color='%231681E1' offset='0%25'/%3E%3Cstop stop-color='%2340B8F7' offset='99.992%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect x='0' y='0' width='56' height='56' rx='1.867'/%3E%3Cpath d='M14.862 47.286l-5.568 1.193a1.493 1.493 0 0 1-1.774-1.773l1.194-5.568A23.225 23.225 0 0 1 4.667 28C4.667 15.113 15.113 4.667 28 4.667c12.887 0 23.333 10.446 23.333 23.333 0 12.887-10.446 23.333-23.333 23.333a23.224 23.224 0 0 1-13.138-4.047zM41.545 21.96c.197-.664-.198-1.062-.856-1.062h-3.226c-.79 0-1.119.332-1.316.797 0 0-1.58 3.884-3.819 6.408-.724.73-1.053.962-1.448.962-.198 0-.493-.232-.493-.896v-6.21c0-.796-.1-1.061-.758-1.061h-5.068c-.494 0-.724.28-.724.63 0 .756 1.119.93 1.119 3.055v4.615c0 .863-.066 1.195-.461 1.195-1.053 0-3.615-3.902-5.135-8.366-.298-.868-.621-1.13-1.415-1.13h-3.292c-.527 0-.856.333-.856.797 0 .83 1.054 4.947 4.905 10.393 2.567 3.718 6.057 5.643 9.347 5.643 1.976 0 2.436-.331 2.436-1.128v-2.79c0-.597.1-1.095.725-1.095.46 0 1.25.232 3.094 2.025 2.106 2.126 2.304 2.988 3.489 2.988h3.686c.395 0 .724-.198.724-.796 0-.864-1.119-2.324-2.699-4.117-.724-.863-1.81-1.793-2.14-2.258-.46-.597-.328-.863 0-1.394 0 0 3.786-5.38 4.181-7.205z' fill='url(%23a)' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
    @media (max-width: 959px) {
      .Header__logo {
        width: 52px;
        height: 52px; } }
  .Header__cta {
    padding-top: 6px;
    text-align: center; }
    @media (max-width: 959px) {
      .Header__cta {
        display: none; } }
  .Header__button {
    display: inline-block;
    height: 44px;
    padding: 12px 20px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    vertical-align: top;
    border-radius: 22px;
    background: #3F8AE0;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
    transition: background 150ms ease-in-out; }
    @media (min-width: 960px) {
      .Header__button:hover {
        background-color: #4896F0; } }
    .Header__button:active {
      background-color: #3982D7; }
  .Header__button_mac {
    padding-left: 17px; }
    .Header__button_mac .Header__button_inner:before {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-top: -7px;
      margin-right: 9px;
      content: '';
      vertical-align: middle;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath d='M17.684 12.658c-.027-2.66 2.15-3.935 2.247-3.998-1.223-1.805-3.128-2.053-3.807-2.081-1.622-.166-3.164.963-3.987.963-.821 0-2.09-.939-3.436-.914-1.767.026-3.397 1.036-4.307 2.633-1.836 3.213-.47 7.974 1.32 10.58C6.588 21.117 7.63 22.55 9 22.5c1.319-.053 1.817-.86 3.412-.86 1.594 0 2.042.86 3.437.833 1.419-.026 2.318-1.3 3.187-2.58 1.004-1.48 1.417-2.912 1.442-2.986-.032-.014-2.767-1.071-2.794-4.248zm-2.622-7.805c.726-.889 1.217-2.122 1.083-3.353-1.047.043-2.316.703-3.067 1.59-.674.787-1.263 2.043-1.105 3.25 1.168.092 2.361-.6 3.089-1.487z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; }
  .Header__button_inner {
    font-family: TT Commons;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    display: inline-block;
    vertical-align: middle;
    color: #FFFFFF; }
  .Header__cta_download_select {
    position: relative;
    display: inline-block;
    text-align: left;
    vertical-align: top;
    margin-right: 8px;
    user-select: none;
    border-radius: 22px; }
    .Header__cta_download_select.Header__cta_download_select_opened {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08); }
      .Header__cta_download_select.Header__cta_download_select_opened .Header__cta_download_selected {
        border-color: #E7E8EC;
        border-radius: 22px 22px 0 0; }
        .Header__cta_download_select.Header__cta_download_select_opened .Header__cta_download_selected:after {
          transform: scale(1, -1); }
      .Header__cta_download_select.Header__cta_download_select_opened .Header__cta_download_dropdown {
        opacity: 1;
        visibility: visible;
        pointer-events: auto; }
  .Header__cta_download_selected {
    font-family: TT Commons;
    font-size: 20px;
    line-height: 1;
    position: relative;
    width: 250px;
    height: 44px;
    padding: 13px 49px 13px 15px;
    appearance: none;
    outline: none;
    cursor: pointer;
    color: #222222;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 22px;
    background-color: #FFFFFF; }
    .Header__cta_download_selected:after {
      position: absolute;
      top: 14px;
      right: 11px;
      display: inline-block;
      width: 19px;
      height: 14px;
      content: '';
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='19' height='14' viewBox='0 0 19 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M5.29 4.306a1.082 1.082 0 0 0-1.5.18 1.038 1.038 0 0 0 .183 1.475l4.865 3.737c.386.297.93.297 1.316 0l4.869-3.737a1.038 1.038 0 0 0 .184-1.474 1.082 1.082 0 0 0-1.5-.181l-4.21 3.232L5.29 4.306z' id='a'/%3E%3C/defs%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='.8'%3E%3Cpath d='M0 0h19v14H0z'/%3E%3Cuse fill='%23909499' fill-rule='nonzero' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; }
  .Header__cta_download_win .Header__cta_download_selected {
    padding-left: 49px; }
    .Header__cta_download_win .Header__cta_download_selected:before {
      position: absolute;
      top: 9px;
      left: 16px;
      display: inline-block;
      width: 24px;
      height: 24px;
      content: '';
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath d='M3.007 11.4L3 5.549l7.2-.978V11.4H3.007zM11.4 4.397L20.998 3v8.4H11.4V4.397zM21 12.6l-.002 8.4-9.598-1.35V12.6H21zm-10.8 6.897l-7.194-.986V12.6H10.2v6.897z' fill='%233F8AE0' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; }
  .Header__cta_download_dropdown {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: 250px;
    margin-top: -1px;
    border: 1px solid #E7E8EC;
    border-radius: 0 0 22px 22px;
    background-color: #FFFFFF; }
    .Header__cta_download_dropdown.Header__cta_download_dropdown_small {
      height: calc(100vh - 150px); }
      .Header__cta_download_dropdown.Header__cta_download_dropdown_small .Header__cta_download_dropdown_inner {
        overflow: scroll;
        height: 100%; }
      .Header__cta_download_dropdown.Header__cta_download_dropdown_small .Header__cta_download_dropdown_list {
        overflow: hidden;
        border-radius: 0 0 22px 22px; }
  .Header__cta_download_option {
    font-family: TT Commons;
    font-size: 20px;
    line-height: 1;
    position: relative;
    padding: 16px 14px 13px;
    cursor: pointer;
    color: #222222;
    transition: background-color 150ms ease-in-out; }
    .Header__cta_download_option:not(:last-of-type) {
      margin-bottom: -1px; }
      .Header__cta_download_option:not(:last-of-type):after {
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 15px;
        display: inline-block;
        width: 224px;
        height: 1px;
        content: '';
        background-color: #E7E8EC; }
    .Header__cta_download_option.Header__cta_download_option_selected, .Header__cta_download_option:hover {
      background-color: #F0F2F5; }
  .Header__note {
    font-size: 13px;
    line-height: 18px;
    max-width: 220px;
    margin: 12px auto 0;
    color: #939393; }
