@charset "UTF-8";
.Footer {
  padding: 36px 0; }
  @media (max-width: 959px) {
    .Footer {
      padding: 25px 0;
      padding-bottom: calc(25px + constant(safe-area-inset-bottom));
      padding-bottom: calc(25px + env(safe-area-inset-bottom)); } }
  .Footer .DesktopMessenger__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 959px) {
      .Footer .DesktopMessenger__inner {
        display: block; }
        .Footer .DesktopMessenger__inner > div {
          max-width: 200px;
          margin: 0 auto;
          text-align: center; } }
  .Footer__link {
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    cursor: pointer;
    color: #5181B8; }
    @media (min-width: 960px) {
      .Footer__link:hover {
        text-decoration: underline; } }
    @media (max-width: 959px) {
      .Footer__link {
        font-size: 15px;
        line-height: 32px; } }
  .Footer__link_terms:before {
    font-size: 16px;
    line-height: 19px;
    display: inline-block;
    margin: 0 12px;
    content: '·';
    color: #939393; }
    @media (max-width: 959px) {
      .Footer__link_terms:before {
        display: none; } }
