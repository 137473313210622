@media (min-width: 960px) {
  .DesktopMessenger {
    position: relative;
    z-index: 1;
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; } }

.DesktopMessenger__inner {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 5px; }
  @media (max-width: 959px) {
    .DesktopMessenger__inner {
      padding: 0 20px; } }
