.Info {
  padding-top: 133px;
  padding-bottom: 21px; }
  @media (max-width: 959px) {
    .Info {
      padding: 2px 0 0; } }
  .Info__title {
    font-family: TT Commons;
    font-size: 46px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: .2px;
    max-width: 750px;
    margin-bottom: 43px;
    color: #222222; }
    @media (max-width: 959px) {
      .Info__title {
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 0;
        margin-bottom: 23px; } }
  .Info__list {
    margin-bottom: 62px; }
    @media (max-width: 959px) {
      .Info__list {
        display: none; } }
  .Info__item {
    display: inline-block;
    width: 295px;
    vertical-align: top; }
    @media (max-width: 959px) {
      .Info__item {
        display: block;
        width: 100%; }
        .Info__item:not(:last-of-type) {
          margin-bottom: 31px; } }
    @media (min-width: 960px) {
      .Info__item:not(:nth-of-type(3n)) {
        margin-right: 31px; } }
    .Info__item.Info__item_chats .Info__item_title:before {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='34' height='34' viewBox='0 0 34 34' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M12.603 2.5h8.937c8.16 0 10.103 1.943 10.103 10.103v8.937c0 8.16-1.943 10.103-10.103 10.103h-8.937C4.443 31.643 2.5 29.7 2.5 21.54v-8.937C2.5 4.443 4.443 2.5 12.603 2.5zm0 2.429c-6.818 0-7.674.856-7.674 7.674v8.937c0 6.818.856 7.674 7.674 7.674h8.937c6.818 0 7.674-.856 7.674-7.674v-8.937c0-6.818-.856-7.674-7.674-7.674h-8.937zm12.579 8.55c-.237 1.044-2.503 4.115-2.503 4.115-.197.303-.276.455 0 .796l1.281 1.29c.8.864 1.401 1.594 1.569 2.097.153.504-.113.76-.644.76h-1.86c-.71 0-.917-.544-2.178-1.757-1.104-1.024-1.577-1.156-1.852-1.156-.374 0-.487.102-.487.613v1.605c0 .441-.146.696-1.328.696-1.971 0-4.136-1.151-5.672-3.274-2.305-3.108-2.937-5.459-2.937-5.934 0-.266.107-.506.638-.506h1.86c.475 0 .655.2.832.696.91 2.55 2.443 4.777 3.073 4.777.237 0 .345-.105.345-.683V14.98c-.069-1.213-.74-1.312-.74-1.743 0-.2.178-.412.474-.412h2.923c.395 0 .532.203.532.658v3.546c0 .379.171.512.289.512.237 0 .433-.132.867-.55 1.34-1.441 2.285-3.659 2.285-3.659.118-.265.337-.506.809-.506h1.86c.562 0 .68.278.562.658l.002-.003z' fill='%23222' fill-rule='nonzero'/%3E%3Cpath d='M0 0h34v34H0z'/%3E%3C/g%3E%3C/svg%3E"); }
    .Info__item.Info__item_calls .Info__item_title:before {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='34' height='34' viewBox='0 0 34 34' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M19.43 20.814l-.891.891a.864.864 0 0 1-.91.2c-.886-.325-1.995-1.153-3.327-2.485-1.33-1.33-2.159-2.44-2.484-3.327a.864.864 0 0 1 .199-.91l.892-.89c1.645-1.645 1.795-4.235.345-5.955l-1.046-1.243c-1.195-1.42-3.347-1.564-4.809-.322a4.033 4.033 0 0 0-1.184 1.752c-.1.296-.165.603-.192.914-.535 3.973 1.38 8.144 5.747 12.51 4.367 4.367 8.536 6.282 12.51 5.748a4.028 4.028 0 0 0 2.665-1.376c1.244-1.461 1.1-3.614-.32-4.809l-1.244-1.048c-1.721-1.447-4.31-1.295-5.954.349l.003.001zm-3.41-3.11c.636.637 1.19 1.119 1.639 1.448 2.577-2.578 6.59-2.814 9.29-.543l1.243 1.047c2.464 2.074 2.71 5.769.594 8.254-1.087 1.258-2.617 2.048-4.178 2.196-4.835.65-9.699-1.582-14.552-6.437-4.854-4.854-7.088-9.718-6.45-14.445.046-.503.15-.997.313-1.48a6.486 6.486 0 0 1 1.909-2.82c2.473-2.103 6.167-1.857 8.239.608l1.044 1.242c2.273 2.696 2.04 6.712-.486 9.238.275.501.757 1.056 1.393 1.692h.001z' fill='%23222' fill-rule='nonzero'/%3E%3Cpath d='M0 0h34v34H0z'/%3E%3C/g%3E%3C/svg%3E"); }
    .Info__item.Info__item_followers .Info__item_title:before {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='34' height='34' viewBox='0 0 34 34' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M5.689 30.349a1.52 1.52 0 0 1-.733-.155c-.818-.4-1.18-1.44-.811-2.325 1.032-2.469 1.6-4.286 1.715-5.365a11.666 11.666 0 0 1-1.817-6.25C4.043 9.442 9.913 4 17.074 4c7.162 0 13.031 5.441 13.031 12.255 0 6.813-5.87 12.254-13.03 12.254-1.536 0-3.035-.25-4.447-.731-1.589 1.6-3.919 2.443-6.94 2.57zm5.81-4.864c.307-.39.806-.531 1.25-.35 1.349.549 2.814.836 4.325.836 5.929 0 10.67-4.415 10.67-9.76s-4.741-9.76-10.67-9.76c-5.928 0-10.67 4.415-10.67 9.76 0 1.84.558 3.605 1.602 5.14.143.212.22.467.22.73-.005 1.277-.505 3.131-1.494 5.645 2.231-.254 3.805-1.014 4.767-2.24z' id='a'/%3E%3C/defs%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h34v34H0z'/%3E%3Cuse fill='%23222' fill-rule='nonzero' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E"); }
  .Info__item_title {
    font-family: TT Commons;
    font-size: 25px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 8px;
    color: #222222; }
    @media (max-width: 959px) {
      .Info__item_title {
        font-size: 22px;
        margin-bottom: 4px;
        margin-left: -2px; } }
    .Info__item_title:before {
      display: block;
      width: 34px;
      height: 34px;
      margin-bottom: 20px;
      margin-left: -2px;
      content: '';
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; }
      @media (max-width: 959px) {
        .Info__item_title:before {
          width: 28px;
          height: 28px;
          margin-bottom: 15px;
          margin-left: 0; } }
  .Info__item_text {
    font-size: 16px;
    line-height: 23px;
    color: #76787A; }
    @media (max-width: 959px) {
      .Info__item_text {
        font-size: 15px;
        line-height: 21px; } }
  .Info__screen {
    overflow: hidden;
    width: 100vw;
    max-width: 950px;
    height: calc(100vw * .64);
    max-height: 607px;
    background: rgba(0, 29, 61, 0.08);
    background-image: url("./img/screen_mac_ru.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 0.5px solid rgba(225, 227, 230, 0.99);
    border-radius: 8px;
    box-shadow: 0 8px 24px 1px rgba(0, 0, 0, 0.06), 0 10px 46px 0 rgba(0, 0, 0, 0.04); }
    .Info__screen.Info__screen_ru {
      background-image: url("./img/screen_mac_ru.png"); }
    .Info__screen.Info__screen_en {
      background-image: url("./img/screen_mac_en.png"); }
    .Info__screen.Info__screen_uk {
      background-image: url("./img/screen_mac_uk.png"); }
    @media (max-width: 959px) {
      .Info__screen {
        width: calc(100vw - 40px);
        height: calc((100vw - 40px) * .64);
        border-radius: 4px;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12); } }
  .Info__screen_win {
    border-radius: 4px; }
    .Info__screen_win.Info__screen_ru {
      background-image: url("./img/screen_win_ru.png"); }
    .Info__screen_win.Info__screen_en {
      background-image: url("./img/screen_win_en.png"); }
    .Info__screen_win.Info__screen_uk {
      background-image: url("./img/screen_win_uk.png"); }
  .Info__screen_lin {
    border-radius: 6px; }
    .Info__screen_lin.Info__screen_ru {
      background-image: url("./img/screen_lin_ru.png"); }
    .Info__screen_lin.Info__screen_en {
      background-image: url("./img/screen_lin_en.png"); }
    .Info__screen_lin.Info__screen_uk {
      background-image: url("./img/screen_lin_uk.png"); }
  .Info__button_public {
    display: none; }
    @media (max-width: 959px) {
      .Info__button_public {
        font-family: TT Commons;
        font-size: 19px;
        line-height: 19px;
        font-weight: 600;
        display: inline-block;
        width: 100%;
        height: 44px;
        margin-top: 20px;
        padding: 14px 20px;
        text-align: center;
        color: #FFFFFF;
        border-radius: 10px;
        background: #3F8AE0;
        transition: background 150ms ease-in-out; }
        .Info__button_public:active {
          background-color: #4896F0; } }
  .Info__list_mob {
    display: none; }
    @media (max-width: 959px) {
      .Info__list_mob {
        position: relative;
        display: block;
        margin-top: 32px;
        padding: 35px 0 32px; }
        .Info__list_mob:before, .Info__list_mob:after {
          position: absolute;
          left: -20px;
          display: inline-block;
          width: 100vw;
          height: 1px;
          content: '';
          background-color: #E7E8EC; }
        .Info__list_mob:before {
          top: 0; }
        .Info__list_mob:after {
          bottom: 0; } }
